@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:opsz,wght@8..144,400;8..144,700;8..144,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');

:root {
  --rectangle-78: rgba(28, 101, 109, 1);
  --rectangle-79: rgba(180, 231, 237, 1);
  --rectangle-80: rgba(13, 47, 51, 1);
  --rectangle-81: rgba(7, 24, 26, 1);
  --rectangle-82: rgba(180, 231, 237, 1);
  --shaddow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
}

.banner {
  /* clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 72%); */
  /* background:url("../img/bannergray.jpg"); */
  background: linear-gradient(#11464B, #124348);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  transition: 0.5s;
  overflow-x: hidden;
  overflow-y: hidden;
  /* margin-bottom: 5rem; */
  /* padding: 20px; */
}

.banner h1 {
  /* font-size: 3rem; */
  /* color: #ef9d10; */
  color: #fff;
  animation: slide 1s forwards;
}

.banner h1 span {
  /* text-shadow: 0 0 10px #ef9d10, 0 0 20px #ef9d10, 0 0 40px #ef9d10, 0 0 80px #ef9d10; */
  /* font-size: 4.5rem; */
  font-size: 56px;
  font-weight: 700;
  line-height: 61px;
  font-family: Helvetica;
  letter-spacing: 2.24px;
  word-wrap: break-word
}

.banner h2 {
  color: wheat;
  font-size: 3rem;
  width: 100%;
  animation: slide 1s forwards;

}

.banner .btns {
  display: flex;
  width: 100%;
  column-gap: 1rem;
  position: relative;
  top: 7rem;
}

/* .banner button {
  background-color: #000;
  outline: 2px solid wheat;
  color: #fff;
  padding: 1rem;
  padding-inline: 3rem;
  width: 50%;
  font-size: 1.5rem;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  position: relative; Add position relative for the pseudo-element
  overflow: hidden; Ensure overflow is hidden for the pseudo-element
}

.banner button::after {
  background-color: orange;
  border-radius: 3rem;
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: -1;
}

.banner button:hover::after {
  transform: translate(0, 0);
}

.banner button:hover {
  border: 2px solid transparent;
  transform: scale(1.25);
  will-change: transform;
  outline: none;
  z-index: 1;
  margin-left: 2rem;
  margin-right: 2rem;
} */

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
}


.banner-scrolled {
  /* position: fixed; */
  border-radius: 10px;
  top: 0;
  left: 0;
  z-index: 1;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-left: 10%;
  /* Add margin to the left side */
  margin-right: 10%;
}


.banneryellow {
  /* z-index: 10; */
  position: absolute;
  /* background: linear-gradient(95deg,#bf6701 10%, #e4ac62 ); */
  background-color: #B4E7ED;
  /* border-top-left-radius: 10%; */
  /* border-bottom-left-radius: 10%; */
  top: 0;
  right:0;
  width: 184px;
  height: 820px;
  z-index: 1;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 97%);
  /* box-shadow: 71px -1px 105.69999694824219px 0px #00000040; */
  filter: drop-shadow(0 6mm 0mm rgb(255, 0, 0));
  /* animation: bannercontainer 1s forwards; */
  animation: moveh2 1.2s alternate ease-in-out;

}

/* .banneryellow::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -30px;
  width: 100%;
  height: 100.5vh;
  background: transparent;
  border-top-left-radius: 10%;
  border-bottom-left-radius: 10%;
  border: 2px solid #F2DDC2;
  z-index: -1;
} */

.banneryellow>div {
  display: none;
  rotate: -90deg;
  font-size: 5rem;
  margin-top: 2rem;
  position: absolute;
  left: -5.4rem;
  top: 16rem;
}

.p1 {
  font-size: 5.5rem;
  color: #1E6E76;
  margin-top: 2rem;
  position: relative;
  font-weight: 800;
  animation: slide2 1s forwards;
  animation-delay: 1s;
  transform: translateX(200%);
  left: 8rem;
}

/* p2 with text bacground */

.p2 {
  font-size: 5.5rem;
  font-weight: 700;
  margin: 0;
  -webkit-text-stroke: 2px #1E6E76;
  -webkit-text-fill-color: transparent;
  animation: slide 1s forwards;
  animation-delay: 1s;
  transform: translateX(-200%);
}

.card-image_banner {
  /* animation: imageAnimation 2s forwards; */
  /* width: 70rem; */
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 30px;
  scale: 0.8;
  top: 2rem;
  animation: moveh2 1.2s alternate ease-in-out;
  z-index: 3;
}

.card-image_banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  scale: 0.7;
  background: transparent;
  /* border-top-left-radius: 10%; */
  /* border-bottom-left-radius: 10%; */
  border: 2px solid #F2DDC2;
  background-color: rgba(255, 255, 255, 0.25);
  filter: blur(50px);
  z-index: -1;
}

.card-content_banner>span {
  /* width: 990px; */
  height: 144px;
  flex-grow: 0;
  object-fit: contain;
  /* font-family: Helvetica; */
  /* font-family: "League Gothic", sans-serif; */
  font-size: 59px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 72px;
  letter-spacing: -0.3px;
  text-align: left;
  color: #fff;
  text-transform: capitalize;
}


.card-content_banner p {
  /* // we serve you with the best applied drafts of companies. from real-time market analytics to precise fair pricing, we redefine<br/> */
  width: 600px;
  height: 128px;
  flex-grow: 0;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.04px;
  text-align: left;
  color: #d6d6d6;
  text-transform: capitalize;
}

/* .card-content_banner p span{
  width: 553px;
  height: 128px;
  flex-grow: 0;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.04px;
  text-align: left;
  color: #d6d6d6;
} */

.bullbg {
  position: absolute !important;
  filter: drop-shadow(18px 18px 7.300000190734863px rgba(0, 0, 0, 0.25));
  ;

  /* top: 2rem; */
  /* left: 4px; */
  z-index: -1;
}

.bullreport{
  position: absolute;
  padding: 1rem;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* z-index: 3; */
}

#bullrep1{
  top: 6rem;
  left: 32rem;
  color: rgba(99, 99, 99, 1);
}
#bullrep2{
  bottom: 6rem;
  color: rgba(99, 99, 99, 1);
}
#bullrep3{
  left: 0rem;
  font-size: 1.2rem;
  top: 15rem;
  font-weight: 800;
  color: rgba(99, 99, 99, 1);
}

#bullrep1 img,#bullrep2 img{
  width: 30px;
  rotate: 90deg;
}

.book-image {
  display: none;
}

.moveh2 {
  position: absolute;
}

/*  */
.frame {
  align-items: center;
  background-color: var(--rectangle-79);
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 13px 60px;
  position: relative;
  border: none;
  /* box-shadow: var(--shaddow); */
  cursor: pointer;
  width: 256px;
  height: 57px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 17px;
  margin: 0px 65px 51.3px 0px;
  padding: 13px 60px;
  border-radius: 10px;
  box-shadow: inset 0 4px 4px 0 rgba(255, 255, 255, 0.25);
  /* background-image: radial-gradient(circle at 50% 23%, #c0f8ff, #90cdd4 12%, #1c656d 32%); */
  /* background-image: linear-gradient(to bottom, #c0f8ff, #90cdd4 33%, #1c656d 100%); */
}

.frame .text-wrapper {
  color: var(--rectangle-81);
  font-family: "Roboto Serif-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 22.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.emptybox{
  position: absolute;
  right: 15rem;
  top: 25rem;
  width: 523px;
  height: 386px;
  background: linear-gradient(180deg, rgba(0, 228, 255, 0.07) 0%, rgba(217, 217, 217, 0) 100%);
}

.emptybox::after{
  content: '';
  position: absolute;
  top: -29rem;
  right: -10rem;
  width: 63%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 228, 255, 0.07) 0%, rgba(217, 217, 217, 0) 100%);
}

.emptybox::before{
  content: '';
  position: absolute;
  top: -33rem;
  left: -50rem;
  width: 50%;
  height: 50%;
  background: linear-gradient(180deg, rgba(0, 228, 255, 0.07) 0%, rgba(217, 217, 217, 0.1) 100%);
}

/*  */
@keyframes moveh2 {
  0% {
    opacity: 0;

  }

  100% {
    /* top: -2rem; */
    opacity: 1;
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }

}


@keyframes slide {
  0% {
    transform: translateX(-200%);

  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slide2 {
  0% {
    transform: translateX(200%);

  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes bannercontainer {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@media screen and (max-width: 875px) {
  .banneryellow {
    display: none;
  }

  .card-image_banner {
    display: none;
  }

  .banner h1 {
    font-size: 2rem;
  }

  .banner h1 span {
    font-size: 2.5rem;
  }

  .banner h2 {
    font-size: 2rem;
  }

  .banner h2 span {
    font-size: 2.5rem !important;
  }
}