@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:opsz,wght@8..144,400;8..144,700;8..144,800&display=swap');

* {
  margin: 0;
  padding: 0;
}

.godmain {
  overflow-x: hidden;
  /* background: radial-gradient(#bf6701 -148%,#17181e); */
  /* background-color: #1E6E76; */
  /* background: linear-gradient(#11464B, #124348); */
}



.main-content {
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
  /* background: conic-gradient(from 180deg at 50% 50%, #0000 0deg, #0000 180deg, #0000 180deg, #0000 360deg, #0000 360deg); */
  /* height: 100rem; */
  /* background: #fff; */
  background: transparent;
}

.card-content_banner {
  top: -2rem;
  left: 12rem;
  position: relative;
  /* top: -5rem; */
  /* left: 8rem; */

  /* margin-left: 5rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100vw;
  row-gap: 24px;
  animation: moveh2 1.2s alternate ease-in-out;
  /* height: 70%; */
}

.alert {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  top: 41%;
  left: 41%; 
  width: 25%;
  z-index: 5;
  text-align: center;
  padding: 1.5rem;
  row-gap: 1rem;
  background-color: #fff;
  color: #000;
  font-size: 1rem;
  border-radius: 20px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
  /* font-weight: 500; */
}

.alert .btn-close{
  width: 20px;
  height: 20px;
  padding: 15px;
}

.alert .btn-close:hover {
  background-color: #9ea2a2;
}

.btnoptions{
  width: 100%;
}

.btnoptions button {
  color: #fff;
  border: none;
  outline: 2px solid transparent;
  cursor: pointer;
  background:#1C656D;
  width: 40%;
  height: 48px;
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  gap: 10px;
  font-family: Roboto Serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  transition: all 0.3s ease;
}

.btnoptions button:hover {
  outline: 2px solid #1C656D;
  color: #1C656D;
  background-color: #fff;
}

.alert div:first-child,.alert div:last-child {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.alert div:first-child {
  align-items: center;
}

.alert-bg{
  content: '';
  position:fixed;
  left: -32rem;
  top: -4rem;
  width: 2050px;
  height: 1000px;
  padding: 0.5rem;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  /* cursor: pointer; */
  z-index: 4;
  /* filter: blur(100px); */
  backdrop-filter: blur(50px);
}

.godmain .btn-close {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-left: 1rem;
  /* color: white; */
  width: 20px;
  font-size: 1.5rem;
  /* left: 2rem; */
}


/*  */



/* loading */


/*  */
@media screen and (max-width: 875px) {
  .main-content {
    display: flex;
    flex-direction: column;
  }

  .card-content_banner {
    width: 70vw;
  }
}

/* width */
::-webkit-scrollbar {
  height: 4px;
  border: none;
  /* width: 8px; */
  display: none;
  border-radius: 16px;

}
.show-scrollbar::-webkit-scrollbar {

  display: block;

}
/* Track */
::-webkit-scrollbar-track {
  background: #1E6E76;
  border-radius: 16px;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #CACACA;
  
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 165, 0, 0.5);

}

.invin1{
  position: relative;
  display: block;
  width: 126%;
  height: 228px;
  background-color: white;
  clip-path: polygon(0% 100%, 0% 0%, 73% 100%);
  z-index: 0;
  top: -14rem;
}

.financeinfo{
  position: absolute;
  display: flex;
  top: 45rem;
  align-items: flex-start;
  justify-content: space-between;
  left: 8rem;
}

.financeinfo img{
  width: 100px;
}

.financeinfocontent{
  margin-left: 1rem;
  position: relative;
}

.financeinfocontent span{
  font-size: 3rem;
  font-weight: 400;
  font-family: 'Helvetica', sans-serif;
}

.financeinfocontent p{
  font-family: 'Roboto', sans-serif;
  width: 60%;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  color: rgba(71, 71, 71, 1);
}

.financeinfocontent hr{
  border-top: 4px solid #B4E7ED;
  height: 3px;
  width: 46%;
  margin-top: 3rem;
  display: inline-block;
}

.financeinfocontent .circle{
  position: absolute;
  top: 9.55rem;
  left: 30.5rem;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #B4E7ED;
}
/* .invin2{
  position: relative;
  display: block;
  width: 99%;
  height: 200px;
  background-color: blue;
  clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 57%);
  z-index: 0;
  top: -12rem;
} */