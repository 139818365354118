.news-panel {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 5rem;
  right: 1rem;
  min-width: 22rem;
  overflow: hidden;
  transition: top 0.3s ease;
  margin-bottom: 0.5rem;
  width:98%;
  height: 48vh;
  margin-left: 20px;
  /* -webkit-mask: linear-gradient(90deg, transparent, black 20%, black 80%, transparent);
  mask: linear-gradient(90deg, transparent, black 20%, black 80%, transparent); */
}

.carousel {
  width: 1094px;
  column-gap: 2rem;
  min-width: 22rem;
  height: 60vh;
  display: flex;
  /* -webkit-mask: linear-gradient(90deg, transparent, black 10%, black 90%, transparent);
  mask: linear-gradient(90deg, transparent, black 10%, black 90%, transparent); */
  transition: transform 0.5s ease;

}

.carousel-item {
  /* width:337px; */
  scroll-snap-type: mandatory;
  scroll-snap-align: start;
  background-color: #fff;

  transition: 0.2s;
}
.newsimg{
  width: 100%;
  /* padding-inline: 0.5rem; */
  object-fit: contain;
  z-index: -1;
  /* filter: brightness(0.7); */
}

.carousel:hover .newimg{
  filter: blur(0px);
}

.news-panel h2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: arial,sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  overflow: hidden;
  text-align: justify;
  width: 20rem;
  z-index: 500;
  cursor: pointer;
  color: #c58af9;
}

.news-panel h2:hover {
  text-decoration: underline;
  
}
.active {
  opacity: 1;
  transform: scale(1);
}

.carouselbtns{
  position: absolute;
  top: 11rem;
  /* right:5rem; */
  width: 90%;
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carousel-btn {
  background-color: transparent;
  border-radius: 50%;
  /* padding: 5px 10px;
  margin: 0 5px; */
  cursor: pointer;
  /* color: #eee; */
  /* width: 30px;
  height: 30px; */
  /* outline: 2px solid #4; */
  border: none;
}

.carousel-btn:hover {
  /* background-color: #eee;
  color: #4e4c4c; */
}

.btn1{
  rotate: 180deg;
}


/* loading */
/* Add a loading animation to loading class */
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* transform: translate(-50%, -50%); */
}

/* Add animation to "spinner" */

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #ef9d10;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Add animation to "text" */

.text {
  color: #3498db;
  font-size: 10px;
  letter-spacing: 1px;
}

.newslink{
  text-decoration: none;
  position: relative;
  display: flex;
  flex-direction: column;
  color: black;
  row-gap: 1rem;
  left: 0.5rem;
  top: 2rem;
  width:337px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 875px) {
  .news-panel{
    display: none;
  }
}



