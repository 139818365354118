* {
  box-sizing: border-box;
}

.card {
  transform-style: preserve-3d;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 295px; */
  width: 330px;
  height: 210px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;
}

.card-section {
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  padding: 1rem;
  flex-wrap: wrap;
  column-gap: 1rem;
  /* row-gap: 3rem; */
  margin-left: 4rem;
  margin-bottom: 15rem;
  width: 100%;
}

.card-content {
  width: 100%;
  text-align: center;
}

.Tilt {
  transform-style: preserve-3d;
  height: 137px;
}

.Tilt:hover {
  z-index: 1;
}

.card-image .onhover {
  width: 0px;
  height: 0px;
  transition: 0.2s;
}

.card-title {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card a {
  text-decoration: none;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  width: 90%;
}

.card:hover a {
  font-size: 1rem;
  line-height: 1.25;
}

.card:hover .onhover {
  width: 80px;
  height: 80px;
  -webkit-mask-image: linear-gradient(180deg, #000, #000 75%, #0000);
  mask-image: linear-gradient(180deg, #000, #000 75%, #0000);
}


.companylogo {
  position: relative;
  top: 1rem;
  height: 100px;
  object-fit: cover;
  transition: 0.2s;
}

.card:hover .companylogo {
  transform: translateZ(2rem);
}

.card:hover .card-content {
  transform: translateZ(2rem);
}

.card:hover {
  height: 300px;
  border: 2px solid white;
}





@media screen and (max-width: 875px) {
  .card-section {
    display: flex;
    justify-content: center;
    row-gap: 5rem;
    margin-left: 0rem;
  }

  .card {
    width: 316px;
  }
}