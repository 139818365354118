.aside {
    margin-top: 2rem;
    position: sticky;
    top: 5rem;
    left: 1rem;
    margin-bottom: 0.5rem;
    width: 21rem;
    height: 1%;
    /* background-color: #fff; Set the background color as needed */
    /* padding: 20px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); Optional: Add a box shadow for styling */
  }

  @media screen and (max-width: 875px) {
    .aside{
      left:0;
      width: auto;
      padding-inline: 1rem;
      position: static;
    }
  }
