.content a{
    text-decoration: none;
}
.sidebar {
    padding: 18px 0 20px 6px;
    width: 250px;
    float: left;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 1px 0px 8px 2px #cccccc42;
    height: 100vh;
    position: relative;
}
.content{
    margin-left: 250px;
    background-color: #F5F5F5;
}
.sidebar-menu .logo {
    margin-bottom: 25px;
     padding: 10px;

}
.sidebar-menu ul{
    padding: 0;
}
.sidebar-menu ul li {
    list-style: none;
}
.sidebar-menu ul li a {
    color: #8E8E93;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
    margin: 4px 0;
    width: 100%;
    padding: 12px;
    transition: .3s;
    transition: .3s;
    display: flex;
    gap: 10px;
    align-items: center;
}
.sidebar-menu ul li a.active{
    background-color: #1F6F77;
    color: #fff;
}
.sidebar-menu ul li a.active svg path{
    fill: #fff;
 }
.sidebar-menu ul li a:hover{
    background-color: #1F6F77;
    color: #fff;
}
.sidebar-menu ul li a:hover svg path{
   fill: #fff;
}
.side-logout{
    position: absolute;
    bottom: 50px;
}
.dash-heading{
    font-size: 24px;
    color: #3A3A3C;
    font-family: "Roboto";
    font-weight: 600;
}
.sidebar-menu ul li a.active {
    background-color: #1F6F77;
    color: #fff;
    border-left: 3px solid #73BBC3;
}
.sidebar-menu ul li a.active img {
    filter: brightness(2);
}
.theme-btn{
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease-in-out 0s;
    background: linear-gradient(90deg, #1F6F77 0%, #4E969E 103.36%);
    font-family: 'Roboto';
    font-weight: 500;
    padding: 10px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.form-data input {
    background-color: #F5F5F5;
    border: none;
    padding: 12px;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Roboto';

}
.form-data select:focus{
    box-shadow: none;
}
.form-data select {
    background-color: #F5F5F5;
    border: none;
    padding: 12px;
    border-radius: 8px;
    font-size: 16px;
}
.form-data input::placeholder{
 color: #8E8E93;   
 font-family: "Roboto";
 font-size: 16px;
font-weight: 400;
}
.form-data textarea::placeholder{
    color: #8E8E93;   
    font-family: "Roboto";
    font-size: 16px;
}
.form-data input:focus{
    box-shadow: none;
    border: 1px solid #000;
}
.form-data label {
    font-size: 16px;
    color: #1F1515;
    font-family: 'Roboto';
    font-weight: 400;
}
.form-data {
    background-color: #fff;
    padding: 20px;
    border-radius:8px;
}
.dash-card{
    box-shadow: 0px 0px 30px 0px #BCBCBC40;

}
.dash-card {
    box-shadow: 0px 0px 34px 8px #bcbcbc69;
    padding: 15px;
    border-radius: 10px;
    /* background-color: #73BBC3;
    color: #fff; */
}
.dash-card h5 {
    font-size: 16px;
    color: #8E8E93;
    font-family: 'Roboto';
}
.dash-card h3 {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 0;
}
.dash-card span {
    font-size: 14px;
    font-family: 'Roboto';
}

