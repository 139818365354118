@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:opsz,wght@8..144,400;8..144,700;8..144,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.form-group label{
  color: #333333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 100;
}

.loginmain{
  background-color: #fff;
    display: flex;
    /* flex-direction: column; */
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    /* overflow-y: hidden; */
}

.loginleftimg img{
  /* height:100vh; */
}

.loginright{
  width: 488px; 
  position: absolute;
  right: 150px;
  top: 6rem;
}

.loginright h1{
  width:407px;
  color:#1C656D;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 48px;
  font-weight: 100;
  line-height: 135%;
  text-align: left;
  margin-bottom: 48px;
}

.loginmain .lbtn{
  align-items: center;
  background-color: var(--rectangle-78);
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 13px 60px;
  position: relative;
  border: none;
  color: #fff;
  font-family: "Roboto Serif-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 22.4px;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
}

.loginmain input {
  margin-bottom: 15px;
  padding: 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  gap: 10px;
  background-color: #F4F4F4;
}

.loginmain input::placeholder {
  color: #999999;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

#remember{
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  margin-top:0;
  text-decoration:underline;
  color:#666666;
}

#remember-me:focus {
  outline: none;
  border: none;
  /* outline: 2px solid #1E6E76; */
}

.loginmain input:focus {
  /* outline: none; */
  outline: 2px solid #1E6E76;
}

#remember-me{
  width: 18px;
  aspect-ratio: 1;
  border: 1px solid #666666;
  border-radius: 0px;
  /* background-color: #fff; */
}

hr{
  width: 222px;
  color: #999999;
}

.or{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-block: 48px;
  color: #999999;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 100;
}

.loginmain button {
  color: #fff;
  border: none;
  outline: 2px solid transparent;
  cursor: pointer;
  background:#1C656D;
  width: 100%;
  height: 48px;
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  gap: 10px;
  font-family: Roboto Serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  transition: all 0.3s ease;
}

[aria-labelledby="button-label"] {
 background-color: #ffffff !important;
  color: #666666 !important;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  height: 46px !important;
}


.loginmain button:hover {
  outline: 2px solid #1C656D;
  color: #1C656D;
  background-color: #fff;
}

  
  .error {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    top: -5rem;
    color: #a94442;
    background-color: #f2dede;
    border: 1px solid #ebccd1;
    padding: 15px;
    margin-bottom: 15px;
  }