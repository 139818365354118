.mobile-view-show {
  display: none;
}
.m-bg{
  margin-top: 100px;
}

@media screen and (min-width: 1024px) {
  .slick-slide {
    padding: 0 25px;
}
  .two-trio::after{
    top: -36px!important;
    height: 160px!important;
}
.two-trio::before {
    position: absolute;
    content: "";
    width: 4px;
    height: 100px !important;
    background: #1D666D;
    top: -52px;
    transform: rotate(48deg);
    right: 39px;
}
  .relative-boxes .r-box {
    width: 160px;
    /* height: 190px; */
}
  .content {
    /* height: 100vh; */
    padding-bottom: 50px;
  }

  .join button {
    width: 220px;
    height: 55px;
  }

  /* .why-us {
    bottom: 45px;
  } */
}
.container{
  max-width: 90%!important;
  padding: 0;
}
@media (min-width: 1440px) {
  .container{
      max-width: 1240px!important;
  }
}

@media screen and (min-width: 768px) {
  .inner-box{
    border: 2px solid #73BBC3;
    height: 150%;
    position: absolute;
    width: 97%;
    top: 80px;
    left: -10px;
  }
  .vision-box{
    margin-top: 100px;
  }
  .arrow-position{
    position: absolute;
    left: 50px;
    bottom: -35px;
  }
  .h-web{
    display: block;
  }
  .user-sec {
  width: 85%;
    margin: auto;
  }
  .help-content {
    position: absolute;
    left: 50%;
    text-align: center;
    top: 86%;
    transform: translate(-50%, -50%);

}
  .help-content .search-box input {
    width: 700px;
}
  .help-section {
    height: 240px;
    border-radius: 0;
}
  .center-para-heading{
    width: 60%;
    margin: 0 auto;
  }
  .about-read{
    padding: 0 50px;
    position: relative;
    z-index: -1;
  }
  .analys-section{
   margin-bottom: 80px;
  }
  .small-table{
    width: 300px;
  }
  .watchList svg{
    display: none;
  }
  .home-slider .slider-arrows {
    /* height: 150px; */
}
.logo img {
  /* max-width: 105px; */
  height: 47px;
}
.m-bg{
  margin-top: 300px;
}
  .benifit-box {
    position: relative;
    width: 85%;
    margin: auto;
  }

}

@media screen and (max-width: 1280px) {
  .signin_page .loginData .lbtn{
    margin-bottom: 25px;
  }
  .value-box.box5 {
    left: 45%;
  }

  .graph-lines img {
    position: absolute;
    right: 16%;
  }
  .nav-links li {
    margin-left: 0px;
}

}

@media screen and (min-width: 1600px) {
  .graph-lines img {
    right: 40%;
  }

  img.arro-bg {
    right: -40px;
  }

  .relative-boxes {
    /* height: 500px; */
  }

  img.arro-bg {
    right: -70px;
    bottom: -120px;
}
}

@media screen and (max-width: 1024px) {
  .user-data .list-group-item {
    font-size: 14px;
    padding: 5px;
    font-weight: 600;
    border: none;
    gap: 5px;
    padding: 10px;
    border-radius: 0;
  }

  .join_membership .card_item{
    width: 50%;
}
  .graph-overflow{
    overflow-x: auto;
  }
  .about-read {
    padding: 0 20px;}
  .why-us-bg{
    width: 100%;
  }
  .why-us{
    top: -35px;
  }
  .analys-section .slider_flex_box .col_10{
    width: 80%;
}
.analys-section .slider_flex_box .col_2{
    width: 20%;
}
  .nav-link{
    margin: 0 8px;
  }
  .loginbtn, .logoutbtn{
    height: 35px;
    font-size: 14px;
    padding: 5px 15px;
  }
  .check-member h3 {
    font-size: 16px;
}


  .join .loginbtn{
    height: 52px;
    font-size: 14px;
    padding: 5px 45px;
  }
  .graph-overflow{
    overflow-x: auto;
  }
  .why p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }

  .why h2 {
    font-size: 20px;
  }

  .why h3 {
    font-size: 18px;
  }

  .user-data {
    padding: 0 40px;
  }

  .list-group-sidebar {
    flex-direction: row;
    overflow-x: auto;
    white-space: nowrap;
    padding: 16px 0 0 0;
    width: 100%;
    gap: 5px;
  }

  .list-group-item {
    display: inline-block;
    white-space: nowrap;
  }

  .list-group-item img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .sidebar-container {
    position: static;
    width: 100%;
  }

  .all-menus {
    width: 100%;
    margin-right: auto;
  }

  .user-data .list-group-item.list-group-item-action.active {
    border: none;
    border-radius: 8px;
  }
}
@media screen and (max-width: 991px) {

  .navbar {
    column-gap: 0;
}
  .start-pages .back-icon{
    display: block;
}
.login-side{
  display: none;
}
.login-side-mob{
  display: block;
}
}
@media screen and (max-width:767.98px) {
  .circle_dot {
    right: 50%;
    top: 99%;
}
  .common-modal.two-card-modal .modal-dialog .custom-modal-body{
    padding: 20px;
  }
  .common-modal.two-card-modal .modal-dialog .roboto-modal .dilog-blue-bg img{
    width: 100px;
  }
  .joinmembership_cards{
    flex-direction: column;
}
.join_membership .card_item{
    width: 100%;
}
  .transaction-card .card{
    width: 80%;
    border: none;
}
  .er-chart{
    width: 100%;
  }
.user-data .donut-chart{
    width: 100%;
  }
  .footer_main .disclamer_hr{
    display: block;
  }
  .footer_main .copy-right{
    max-width: 100%;
  }
  .why-us {
    margin: 0;
    padding: 24px 5px 34px 5px;
  }
  .why-us-bg{
    background-color: #fff;
  }
  .analys-section{
    margin-bottom: 70px;
  }
  .analys-section .slider_flex_box .col_10{
    width: 100%;
}
.dotted-circle.stock-box1::before {
  content: "";
  position: absolute;
  top: 60px;
  left: 21%;
  transform: rotate(90deg);
}
.dotted-circle.stock-box2::before {
  content: "";
  position: absolute;
  left: -85px;
  transform: rotate(90deg);
  top: 66px;
}
  section{
    margin: 40px 0;
  }
  .sub-heading {
    font-size: 20px;
    font-weight: 400;
}
  .help-box {
    margin-bottom: 10px;
  }
  .stock-boxes{
    flex-wrap: wrap;
}


  .w-20{
    width: 48%;
}
.dupont-section .w-20{
  width: 50%;
  height: 50%;
  margin-bottom: 10px;
}
  .quality-min {
    min-height: 235px;
}
  .modal-90w.modal-dialog {
    max-width: 90%;
    width: 100%;
}
.data-page ul li {
  font-family: 'Roboto';
  font-size: 14px;
  color: #000;
}
.data-page p {
  font-family: 'Roboto';
  font-size: 14px;
  color: #000;
}
  .company-read img {
    width: 100%;
    display: none;
}
  .value-box.box5 {
    left: 33%;
}
  .value-box {
    width: 150px;
}
.value-box span {
  color: #747474;
  font-size: 14px;
  font-family: 'Roboto';
}
.value-box h3 {
  font-size: 18px;
  color: #242424;
  font-family: 'Roboto-bold';
  margin-top: 6px;
}
.graph-lines img {
  position: absolute;
  right: 8%;
}
  .search-box img {
    position: absolute;
    left: 10px;
    top: 7px;
}

.search-box input {
  padding: 5px;
  padding-left: 40px;
}
  .form-data label {
    font-size: 14px;
}

  .form-data input {
    padding: 8px;
    font-size: 14px;
}
.form-data select {
  padding: 8px;
  font-size: 14px;
}
  .user-table {
    overflow-x: auto;
}
  .dash-heading {
    font-size: 18px;
}
  .theme-btn {
    font-size: 12px;
    font-weight: 500;
    padding: 6px 9px;
}
  .people-card h3 {
    color: #1D666D;
    font-size: 18px;
    margin-bottom: 2px;
}
  .people-card .card-img {
    padding: 0;
    border-radius: 8px 8px 0 0;
}
  .home-color {
    margin: 8px;
}
  .titledesc p {
    line-height: 20px;
    font-size: 14px;
}
  #remember{
    font-size: 12px;
  }
  #forgot{
    font-size: 12px;
  }
  .loginData h1 {
    font-size: 32px;
    width: 60%;
    font-weight: bold;
}
  .form-group label {
    font-size: 14px;
}
.or {
  margin-block: 20px;
}
  .loginData input, select {
    padding: 14px;
    font-size: 14px;
  }
  .heading {
    font-size: 24px;
    font-family: "Roboto-bold";
}
  .profilePic img {
    /* margin: 10px 18px; */
    height: 75px;
    width: 75px;
  }
  .membershipCard p {
    font-size: 12px;
}
.membershipCard h3 {
  font-size: 16px;
}
  .membershipCard {
    padding: 6px;
    margin-bottom: 10px;
}
  .membership-row .order-1 {
    order: 1; /* Membership Card Number section first */
  }
  .membership-row .order-2 {
    order: 2; /* Preview section second */
  }
  .membership-row .order-3 {
    order: 3; /* Select Your Card section third */
  }
  .heading2 {
    font-size: 20px;
}

  .white-box {
    padding: 16px;
}
  .watchList p {
    font-size: 12px;
    margin-bottom: 0;
}
  .watchList {
    display: flex;
    overflow-x: auto;
    gap: 16px; /* Adjust spacing between items */
    scroll-snap-type: x mandatory;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.watch-item {
    flex: 0 0 80%; /* Make each item take full width of the screen on mobile */
    scroll-snap-align: center;
    box-sizing: border-box;
    padding: 20px;
    background: #fff;
    margin-bottom: 10px;
    width: 200px;
    border-radius: 8px;
    position: relative;
}

  .watchlist.white-box{
    background-color: transparent;
  }
  /* ////side bar// .*/
  .sidebar{
    display: none;
  }
  .content{
    margin-left: 0;
  }
  .faq .accordion-item button {
    font-size: 14px;
    font-family: "Roboto";
}
  .faq .accordion-item .accordion-header.active, .accordion-item .accordion-body {
    font-size: 14px;
    color: #1D666D;
    font-family: 'Roboto';
}
  .contact-details .robot {
    position: absolute;
    bottom: -35px;
    right: 0;
    max-width: 130px;
  }

  .contact-list.footer-list ul li {
    margin: 5px;
    height: 40px;
    width: 40px;
  }

  .contact-details h2 {
    font-weight: 500;
    font-size: 24px;
  }

  .section-vision .mobile-view-show {
    height: 160px;
    margin-left: 45px;
  }

  .vision h3 {
    margin-bottom: 20px;
  }
  .vision h2 {
    font-weight: 600;
    text-align: center;
  }

  .home-heading {
    font-size: 32px;
  }

  .vision-box {
    border-radius: 30px;
  }

  .section-vision button.view {
    width: 100%;
  }

  .section-vision {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 28px;
  }

  .mobile-view-show .man-img {
    max-width: 240px;
    /* margin-top: 180px; */
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-view-show {
    display: block;
  }

  .about-read h3 {
    display: none;
  }


  .why-us h2 {
    font-size: 24px;
  }

  .why-us p {
    font-size: 14px;
    text-align: left;
    padding: 0 10px;
    margin-bottom: 5px;
  }

  .why-us a {
    float: left;
    font-size: 14px;
    margin-left: 10px;
  }

  .home-color.white-header .navbar {
    display: none;
  }

  .user-data .list-group-item.list-group-item-action.active {
    font-size: 15px;
  }



  .user-data {
    padding: 0 20px;
    overflow-x: hidden;
  }

  /* .why p {
    font-size: 12px;
    line-height: 15px;
    margin-top: 2px;
  } */
  .list-box {
    padding: 8px;
    border-radius: 10px;
}
.insight-list ul li {
  font-size: 14px;
}
  .graph-box table th {

    font-size: 14px;
    padding: 0;
    line-height: 18.6px;
    padding-left: 0;
    white-space: nowrap;
  }

  .login-img img {
    width: 100%;
  }

  .graph-box table td {
    font-size: 14px;
    padding: 0;
    text-align: right;
    line-height: 15.6px;
  }

  .data-heading h2 {
    font-size: 24px;
  }

  .slider_section .slick-slide {
    padding: 15px;
  }
  .slick-slide {
    padding: 0 5px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    margin-left: 0;
  }

  .about-slider1 .slick-list {
    height: 300px;
  }
  .slider_section .slick-list {
    clip-path: none!important;
}
.slider_section .slide-item h3 {
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
}
  .slider-container {
    margin: 0px auto;
  }

  .about-page .home-heading {
    text-align: center;
    font-size: 24px;
  }

  .about-page .what-p p {
    text-align: center;
    font-size: 20px;
    padding: 0 15px;
  }

  .slider2 .slick-slide {
    margin: 0;
  }
    .slide-item p {
        font-size: 12px;
        line-height: 15px;
    }
  .about-slider-item {
    padding: 20px 15px
  }

  .slide-item .icon {
    height: 50px;
    width: 50px;
  }

  .slider_section .slide-item .icon {
    height: 30px;
    width: 30px;
    padding: 5px;
    margin-bottom: 0;
}
  .last-column {
    order: -1;
  }

  .people-card {
    margin: 18px 25px;
  }
  .slider-arrows {
    height: 30px;
    margin-top: 0;
}
.user-icon {
  margin-top: 40px;
}
  .user-sec h3 {
    margin: 0;
    line-height: 28px;
    font-size: 18px;
    font-family: "Roboto";
}

  table.theme-table th {
    white-space: nowrap;
  }

  .stock-line {
    height: 112px;
  }

  .table-container {
    width: 100%;
    overflow: auto;
  }

  .h-para {
    font-size: 14px;
  }

  .plan-card {
    padding: 20px;
    margin-bottom: 20px;
  }

  .plan-card .price {
    font-size: 40px;
  }

  .plan-card p {
    font-size: 14px;
  }

  .plan-card ul li {
    font-size: 14px;
  }

  .join h1 {
    font-size: 32px;
  }

  .join p {
    font-size: 14px;
  }

  .contact-form {
    position: relative;
    width: 100%;
    left: 0;
    top: 50px;
    box-shadow: 0px 4px 70px 0px #4C4C4C1F;
    padding: 30px;
  }
}




@media (max-width:1400px) and (min-width:1200px) {
  img.arro-bg {
    right: -70px;
    bottom: -105px;
  }

}

@media (max-width:1200px){

  .user-data .graph-box table th{
    font-size: 14px;
  }
  .user-data .graph-box table td{
    font-size: 16px;
    padding: 0 0 0 10px;
  }
}
@media (max-width:1200px) and (min-width:992px) {

  img.arro-bg {
    right: -75px;
    bottom: -100px;
  }

}

@media (min-width: 768px) and (max-width: 991.98px) {
  img.arro-bg {
    right: -52px;
    bottom: -105px;
}


}


@media screen and (max-width: 520px) {
  .show-scrollbar {
    overflow: unset;
}
  .dupont-section {
    display: inline-flex;
    column-gap: 10px;
    flex-wrap: wrap;
}
  .dupont-section .w-20{
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }
  .graph-lines img {
      position: absolute;
      right: -27%;
  }
  button{
    padding: 5px 10px;
  }
  .border-btn{
    font-size: 14px;
  }
  .text-btn{
    font-size: 14px;
  }
}
@media screen and (max-width: 426px) {
  img.arro-bg {
    right: -52px;
}


  .about-slider-item {
    padding: 10px 5px;
  }
  .slide-item p{
    margin-top: 5px;
    font-size: 10px;
  }
  .graph-box table td {
    font-size: 12px;
}
.graph-box table th {
  font-size: 12px;
}
  .slider-arrows button {
    height: 30px;
    width: 31px;
}
  .value-box.box5 {
    left: 30%;
}
.graph-lines img {
  position: absolute;
  right: -50%;
}
.g-img1{
  top: 160px;
}
.g-img2 {
  top: 202px;
}

.g-img3 {
  top: 305px;
}
.g-img4 {
  top: 324px;
}

.value-box.box1 {
  margin-top: 80px;
}
  .contact-list.footer-list ul li {
    margin: 2px;
    height: 35px;
    width: 35px;
  }
  .small-table th {
    font-size: 12px;
    letter-spacing: 0.1px;
}
.small-table td{
  font-size: 12px;
  letter-spacing: 0.1px;
}
}
@media screen and (max-width: 376px) {
  .value-box.box1 {
    margin-top: 70px;
}
    .graph-lines img {
        right: -80%;
    }
}
@media screen and (max-width: 330px) {
  .graph-lines img {
    right: -100%;
}
}