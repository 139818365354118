@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:opsz,wght@8..144,400;8..144,700;8..144,800&display=swap');

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Medium.ttf");
    font-weight: 600;
}

.main-heading {
    color: #3A3A3C;
    font-size: 36px;
    font-family: "Helvetica", sans-serif;
    font-weight: 400;
    margin-bottom: 16px;
}

p.para {
    font-size: 20px;
    color: #747474;
    font-family: "Roboto";
    font-weight: 400;
    margin-bottom: 23px;
}

.company-box {
    border-radius: 8px;
    padding: 10px;
    max-height: 175px;
    width: 100%;
}

.date {
    width: 110px;
    margin: auto;
    background: #ffffff3d;
    padding: 4px;
    text-align: center;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.date span {
    color: #fff;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 11px;
    letter-spacing: 1px;
}

.company-box img {
    margin: 6px auto;
    display: flex;
    justify-content: center;
    width: 150px;
    height: 75px;
    z-index: 1;
    position: relative;
}

.company-box h3 {
    color: #fff;
    font-size: 18px;
    font-weight: bold; /* Makes the text bolder */
    text-align: center;
    font-family: 'Roboto';
    margin-top: 15px; /* Adds space to move it down */
}

.landing-cards {
    position: relative;
    width: 200px;
    /* Adjust width as needed */
    height: 300px;
    /* Adjust height as needed */
}

.gradient-background {
    width: 100%;
    height: 100%;
    background: rgba(30, 110, 118, 1);
    position: absolute;
    top: 0;
    left: 0;
}

.overlapping-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-color {
    background-image: url(../../components/img/home-bg2.svg);
    background-repeat: no-repeat;
    background-size: cover;
    margin: 18px;
    border-radius: 8px;
    /* border: 2px solid #73BBC3; */
    background-position: bottom;
    padding-bottom: 50px;
    margin-bottom: 60px;
}

.lining-bg {
    background-image: url(../../components/img/clipart.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.landing-content h1 {
    line-height: 62px;
    font-size: 64px;
    color: #fff;
    font-family: "Helvetica", sans-serif;
}

.landing-content p {
    color: #FAFAFA;
    font-size: 20px;
    font-family: 'Roboto';
    letter-spacing: 0.25px;
    font-weight: 400;
    margin-right: 20px;
    line-height: 29px;
    margin-bottom: 23px;
}

button.view {
    background: linear-gradient(89.85deg, #BDF8FE 63.34%, #73BBC3 102.23%);
    color: #1D666D;
    font-size: 18px;
    font-family: 'Roboto-md';
    border-radius: 8px;
    padding: 10px;
    width: 208px;
    text-align: center;
}

/* .bg-lines {
    background-color: #13474C;
    background-image: url(../img/horizontal.svg);
    margin: 20px;
    background-repeat: repeat-y; 
    background-position: top;
    padding: 20px; 

} */

.bg-lines img {
    margin: 40px;
}

.bg-lines {
    position: absolute;
}

.verticle {
    left: 20%;
}

h1 span {
    font: 26px Monaco, MonoSpace;
    height: 200px;
    position: absolute;
    width: 20px;
    left: 0;
    top: 0;
    transform-origin: bottom center;
    color: #fff;
}

.round-bg {
    position: relative;
    width: 124px;
    /* background-color: #13474c; */
    left: 50%;
    border-radius: 50%;
    /* border-bottom: 2px solid #73BBC3; */
    transform: translate(-50%, -50%);
    top: -180px;
}

.char1 {
    transform: rotate(6deg);
}

.char2 {
    transform: rotate(12deg);
}

.char3 {
    transform: rotate(18deg);
}

.char4 {
    transform: rotate(24deg);
}

.char5 {
    transform: rotate(30deg);
}

.char6 {
    transform: rotate(36deg);
}

.char7 {
    transform: rotate(42deg);
}

.slider-bg {
    background-color: #73BBC3;
    height: 130px;
}

.ana-card .card-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ana-card h3 {
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    font-family: "Roboto";
    letter-spacing: 0.55px;
    color: #8E8E93;
    margin-bottom: 12px;
}

.ana-card {
    padding: 16px 15px;
    border-radius: 8px;
    /* box-shadow: 0px 0px 15px 2px #bcbcbc4f; */
    box-shadow: 0px 4px 70px 0px #4C4C4C1F;

    background-color: #fff;
    margin: 0 10px;
    height: 145px;
    transition: .3s;
    cursor: pointer;
}

.slider-bg {
    background-color: #73BBC3;
    height: 130px;
    position: absolute;
    width: 100%;
    z-index: -1;
    bottom: -30px;
}

.ana-card p {
    color: #fff;
    font-size: 15px;
    font-family: "Roboto";
    font-weight: 400;
    transition: transform .1s ease;
    transform: translateY(100%);
    opacity: 0;
    text-align: center;
    margin-bottom: 0;
    line-height: 20px;
}

.ana-card .card-img img {
    height: 50px;
    width: 65px;
    object-fit: cover;
    margin-bottom: 10px;
}

.ana-card:hover p {
    /* display: block; */
    transform: translateY(0);
    opacity: 1;

}

.ana-card:hover h3 {
    color: #FFFFFF;
}

.ana-card:hover {
    min-height: 210px;
    background-color: #1E6E76;
}

.ana-card:hover img {
    filter: brightness(0) invert(1);
}

.finance-sidebar {
    background-color: #73BBC3;
    padding: 15px;
    border-radius: 8px;
}

.finance-sidebar ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 400;
    margin: 0 10px;
}

.finance-sidebar ul li {
    margin: 10px 0;
    list-style: none;
    line-height: 40px;
    padding-left: 15px;
    border-radius: 10px;
    color: #fff;

}

.finance-sidebar ul li:hover {
    background-color: #1C656D;
    border-radius: 10px;
}

.search-box {
    position: relative;
}

.search-box input::placeholder {
    padding-left: 0;
    color: #FFFFFF;
}

.finance-sidebar ul li.active {
    background-color: #1C656D;
}

.finance-sidebar .search-box  input{
    color: #fff;
}
.search-box input {
    padding: 10px;
    width: 100%;
    border: 1px solid #E5E5EA;
    border-radius: 8px;
    padding-left: 37px;
    background-color: transparent;
    color: #000;
    background: #fff;
}

.search-box input:focus {
    outline: none;
}

.companyGrph {
    height: 40px;
    width: 40px;
}

.finance-sidebar ul {
    padding-left: 0;
}

.search-box img {
    position: absolute;
    left: 10px;
    top: 12px;
    /* filter: brightness(0) invert(1); */
}

.round-bg .text {
    position: absolute;
    left: 6px;
    top: 8px;
}

.round-bg .arrowText {
    position: absolute;
    left: 45px;
    top: 50px;
}

.carousel-with-arrows .react-multi-carousel-list {
    padding-right: 80px;
    /* Leave enough space for the arrows on the right */
}

.carousel-with-arrows .react-multi-carousel-arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.carousel-with-arrows .react-multi-carousel-arrow--left {
    right: 80px;
    /* Position the left arrow beside the right one */
}

.carousel-with-arrows .react-multi-carousel-arrow--right {
    right: 20px;
    /* Position the right arrow at the edge */
}


/* Style for the company box */
.company-box {
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    height: 100%;
    margin-bottom: 30px;
}

/* Scale effect on hover */
.company-box:hover {
    transform: scale(1.05);
    max-height: 190px;
}

/* Initially hide the button */
.company-box .hover-btn {
    display: none;
    background-color: transparent;
    color: #431F80;
    padding: 3px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid #431F80;
    border-radius: 24px;
    font-size: 12px;
    font-family: 'Roboto-md';
    margin-bottom: 8px;
}

.max-height-box {
    max-height: 200px;
}

.max-height-box {
    /* height: 200px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Show the button when hovering over the company box */
.company-box:hover .hover-btn {
    display: inline-block;
}

.company-box h3 {
    margin-bottom: 15px;
    transition: color 0.3s ease;
}

.company-box:hover .date {
    visibility: hidden;
    display: none;
}

.home-slider .slick-slide {
    padding: 0;
}

.analys-section .slider_box {
    height: 250px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.analys-section .slider_flex_box {
    display: flex;
    gap: 30px;
}

.analys-section .slider_flex_box .col_10 {
    width: 90%;
}

.analys-section .slider_flex_box .col_2 {
    width: 10%;
}

.rotate {
    animation: rotation 15s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.white-header .logoutbtn {
    background: transparent;
    border: 1px solid #fff;
    transition: .3s ease-in-out;
}

.home-color.white-header .logoutbtn:hover {
    background: linear-gradient(90deg, #1F6F77 0%, #4E969E 103.36%);
    border: none;
}

.search-box input::placeholder {
    color: #8E8E93;
}

/* ==========================hero section============================== */
.hero-section .flex_box_wrapped {
    display: flex;
    gap: 40px;
    padding-bottom: 50px;
    /* align-items: center; */
}

.hero-section .flex_box_wrapped  .col_md_4 {
    width: 40%;
}
.hero-section .flex_box_wrapped  .col_md_4 .landing-content{
    padding-top: 50px;
}
.hero-section .flex_box_wrapped  .col_md_8 {
    width: 60%;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
    width: 100vw;
    /* Full viewport width */
    position: fixed;
    /* Fix to the entire viewport */
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    /* Optional: Add a subtle background while loading */
    z-index: 9999;
    /* Ensure it stays on top */
}

.spinner {
    width: 4rem;
    height: 4rem;
}

.search-box .home-search {
    background-color: transparent;
}

.search-box .home-search-img {
    filter: brightness(0)invert(1);
}

.search-box .home-search::placeholder {
    color: #FFFFFF70;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;
}

.slider-container.home-slider {
    height: 220px;
    margin: 0;
}































@media screen and (max-width: 1200px) {
    .landing-content h1 {
        font-size: 50px;
        line-height: 50px;
    }

    .landing-content p {
        font-size: 18px;
        line-height: 26px;
    }

    .hero-section .flex_box_wrapped {
        gap: 30px;
    }
}

@media screen and (max-width: 1024px) {
    .round-bg .text {
        left: 18px;
        top: 30px;
        height: 90px;
        width: 90px;
    }
    .round-bg .arrowText {
        top: 60px;
    }
    .landing-content h1 {
        font-size: 45px;
    }
    .landing-content p {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
    }
    .hero-section .flex_box_wrapped {
        gap: 10px;
    }

    .hero-section .flex_box_wrapped .hero-section .col_md_4 {
        width: 50%;
    }

    .hero-section .flex_box_wrapped .hero-section .col_md_8 {
        width: 50%;
    }
}

@media screen and (max-width:991px) {
    .finance-sidebar .search-box input {
        color: #000;
    }
    
    .finance-sidebar {
        background-color: #fff;
        padding: 10px;
        border-radius: 8px;
    }

    .search-box input {
        border: 1px solid #73BBC3;
        border-radius: 8px;
        color: #000;
    }

    .search-box .home-search-img {
        filter: none;
    }

    .finance-sidebar ul {
        padding-left: 0;
        display: flex;
        align-items: center;
        overflow-y: auto;
        margin-bottom: 0;

    }

    .finance-sidebar ul li {
        list-style: none;
        line-height: 18px;
        padding: 10px 4px;
        border-radius: 10px;
        font-size: 16px;
        display: flex;
        align-items: center;
        margin: 10px 12px 0 0;
    }

    .finance-sidebar ul li a {
        font-size: 14px;
        margin: 0 10px;
    }

    .companyGrph {
        height: 30px;
        width: 30px;
        margin-right: 10px;
    }

    .company-box {
        height: 100%;
        position: relative;
    }

    .finance-sidebar ul li a {
        white-space: nowrap;
        color: #8E8E93;
    }

    .finance-sidebar ul li img {
        filter: blur(0) brightness(0.5);
    }

    .finance-sidebar ul li.active a {
        color: #fff;
    }

    .finance-sidebar ul li:hover a {
        color: #fff;
    }

    .finance-sidebar ul li:hover img {
        filter: blur(0)brightness(1);
    }
}

@media screen and (max-width:767.98px) {
    .slider-container.home-slider {
        height: auto;
        margin-bottom: 20px;
    }    
    .slider-arrows button svg{
        max-width: 30px;
    }
    .hero-section .flex_box_wrapped{
        flex-direction: column-reverse;
        padding: 0;
    }
    .hero-section  .flex_box_wrapped .col_md_4{
        width: 100%;
    }
    .hero-section .flex_box_wrapped  .col_md_4 .landing-content{
        padding-top: 0;
    }
     .hero-section  .flex_box_wrapped .col_md_8 {
       max-width: 80%;
       margin: auto;
    }
    .hero-section .col_md_8{
        width: 100%;
    }
    .home-color {
        padding-bottom: 80px;
    }
    .company-box h3 {
        color: #fff;
        font-size: 18px;
    }

    .landing-content p {
        font-size: 16px;
        margin-right: 0;
    }

    .analys-section .slider_flex_box {
        gap: 0px;
        flex-direction: column;
        align-items: center;
    }

    .analys-section .slider_box {
        height: auto
    }

    /* .home-slider .slider-arrows{
        display: none!important;
    } */
    .home-slider .slick-list {
        min-height: 170px;
    }

    .home-color {
        border-radius: 15px;
    }

    .landing-content h1 {
        font-size: 35px;
        line-height: 45px;
    }
    .hero-section .view {
        width: 120px;
        margin-bottom: 20px;
        font-size: 14px;
        height: 35px;
        padding: 0;
    }

    .round-bg {
        position: relative;
        height: 90px;
        width: 91px;
        /* background-color: #13474c; */
        /* left: 41%; */
        border-radius: 50%;
        bottom: 50px;
        top: -80px;
    }

    .round-bg .text {
        left: 0px;
        top: 20px;
        height: 90px;
        width: 90px;
    }

    .round-bg .arrowText {
        left: 26px;
        top: 34px;
        height: 25px;
        width: 25px;
    }

    .round-bg .arrowText {
        left: 33px;
        top: 55px;
    }

    .main-heading {
        font-size: 24px;
        font-weight: bold;
    }

    .max-height-box {
        /* height: 100px; */
    }

}
@media screen and (max-width:576px){
    .hero-section  .flex_box_wrapped .col_md_8{
       max-width: 100%;
       width: 100%;
     }
}

