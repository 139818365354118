.about_us .why-us p{
    margin-bottom: 0;
}
.about-slider-item {
  position: relative;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slider_section .slick-list{
    clip-path: polygon(37% 4%, 65% 4%, 100% 0, 100% 100%, 64% 96%, 37% 96%, 0 100%, 0 0);
}
.slick-current{
    padding-top: 36px;
}
.slick-current .about-slider-item{
    min-height: 350px;
    padding: 18px 15px;
}