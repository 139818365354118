@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.howtopay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    gap: 60px;
    /* margin-top: 50px;
    margin-bottom: 50px; */
}

.howtopayheading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    width: 470px;
}

.howtopayheading p {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #666666;
}

.howtopayheading p:nth-child(3) {
    color: #1A1A1A;
    width: 229px;
}

.howtopayheading h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 100;
}

.creditdebit,
.upi {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 841px;
    height: 95px;
    border-radius: 12px;
    background-color: #F4F4F4;
    padding-block: 16px;
    padding-left: 24px;
    padding-right: 97px;
    /* gap: 20px; */
}

.creditdebit:hover,
.upi:hover {
    background-color: #E0E0E0;
    cursor: pointer;
}

.creditdebit > div{
    display: flex;
    width: 361px;
    /* justify-content: space-between; */
    gap: 20px;
    align-items: center;
}

.creditdebit > div div{
    display: flex;
    width: 70px;
    justify-content: space-around;
}

.upi > div{
    display: flex;
    width: 361px;
    justify-content: space-between;
    align-items: center;
}

.upi > div div{
    display: flex;
    width: 249px;
    justify-content: space-between;
}

.creditdebit > div > p,.upi > div > p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
}