@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:opsz,wght@8..144,400;8..144,700;8..144,800&display=swap');

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Medium.ttf");
    font-weight: 600;
}

body {
    overflow-x: hidden;
}

div p {
    color: #747474;
    font-size: 16px;
}

section {
    margin: 50px 0;
}

.list-group-sidebar {
    position: sticky;
    left: 2px;
    top: 100px;
    width: 200px;
}

.all-menus {
    width: 95%;
    margin-right: auto;
}

.data-heading {
    align-items: center;
}

.data-heading h2 {
    font-size: 36px;
    color: #1D666D;
    font-family: "Helvetica", sans-serif;
    font-weight: 700;
}

.rule {
    height: 2px;
    background: #73BBC3;
}

.rule:after {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 10px;
    float: left;
    background: #73BBC3;
    margin-top: -3px;
}

.rule:after {
    float: right;
}

.data-heading2 {
    color: #1D666D;
    font-size: 24px;
    font-weight: 400;
    font-family: "Roboto";

}

.content1 {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    color: #3A3A3C;
}

.graph-box table tr {
    height: 56px;
}

.user-data .block-heaidng {
    display: inline-block;
}

.user-data .graph-box table th {
    background-color: #fff;
    font-family: "Roboto";
    font-size: 14px;
    padding: 0;
    line-height: 10.6px;
    font-weight: 400;
    color: #3A3A3C;
}

.user-data .graph-box table td {
    color: #73BBC3;
    font-family: "Roboto";
    font-size: 16px;
    padding: 0 0 0 10px;
    text-align: right;
    background-color: #fff;
    line-height: 21.6px;
    font-weight: 700;
}

.graph-box {
    border: 0.5px solid #AEAEB2;
    border-radius: 10px;
    padding: 4px;
    /* box-shadow: 0px 4px 70px 0px #4C4C4C1F; */
}

.blue-border {
    border: 2px solid #BDF8FE;
}

.graph-box p {
    /* font-size: 20px; */
    /* color: #343A40; */
}

.dark-bg {
    background-color: #1D666D;
    border-radius: 8px;
    position: relative;
    /* min-height: 230px; */
    margin-left: 15px;
    height: auto;
}

.dark-bg .light-bg {
    background-color: #DBFBFF;
    position: relative;
    height: 100%;
    top: -12px;
    margin-right: 0;
    border-radius: 10px;
    left: -12px;
    padding: 24px;
}

.why h2 {
    font-size: 24px;
    font-family: 'Roboto-bold';
    color: #1D666D;
    margin-bottom: 0;
}

.why img {
    margin-right: 10px;
}

.why h3 {
    font-size: 20px;
    color: #242424;
    font-family: 'Roboto';
    font-weight: 600;
}

.why p {
    /* font-size: 14px; */
    color: #747474;
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 24px;
    margin-top: 10px;
}

.graph-title {
    font-size: 20px;
    font-weight: 700;
    color: #343A40;
}

.select-months {
    position: relative;
    width: 150px;
}

.select-months select {
    border: none;
    outline: none;
    padding: 8px, 16px, 8px, 16px;
    box-shadow: 0px 0px 16px 0px #00000014;
}

.select-months select:focus {
    box-shadow: none;
}

.chart-base span {
    font-size: 12px;
    color: #5F666C;
    font-weight: 600;
}

.yellow-dot {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #FDB600;
}

.heading2 {
    font-size: 24px;
    font-family: 'Roboto';
    color: #242424;
    font-weight: 600;
}

.heading3 {
    font-size: 16px;
}

.content2 {
    color: #747474;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto';
}

.quality-box {
    border: 2px solid #1D666D;
    padding: 15px 12px;
    border-radius: 8px;
    margin-bottom: 10px;
    height: 100%;
}

.quality-box h3 {
    font-size: 20px;
    color: #242424;
    font-family: 'Roboto';
    font-weight: 400;
}

.quality-box p {
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    font-family: 'Roboto';
    margin-top: 15px;
    margin-bottom: 0;
}

.quality-icon {
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #73BBC3;
    border-radius: 50%;
    margin-right: 10px;
}

.news-items {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

.news-items h2 {
    padding: 0 50px 0 0;
    color: #73BBC3;
    font-weight: 700;
    font-size: 36px;
    font-family: "Helvetica", sans-serif;
}

.item-content h3 {
    font-size: 20px;
    font-family: 'Roboto';
    font-weight: 600;
    color: #242424;
}

.item-content p {
    color: #747474;
    font-size: 16px;
    font-family: 'Roboto';
}

.insight-list ul {
    margin-bottom: 0;
}

.insight-list ul li {
    color: #747474;
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 24px;
    font-size: 16px
}

.list-box {
    padding: 24px;
    border-radius: 10px;
    height: 100%;
}

.list-box h3 {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    font-family: 'Roboto';
}

.list-box.headwinds {
    border: 2px solid #6D1D1D;
    margin-bottom: 10px;
}
.show-scrollbar {
    overflow: auto hidden;
}
.list-box.headwinds h3 {
    color: #6D1D1D;
}

.list-box.tailwind {
    border: 2px solid #236D1D;
}

.list-box.tailwind h3 {
    color: #236D1D;
}

.graph-box.grey-bordered {
    border: 1.5px solid #D9D9D9;
    height: 100%;
}

.dupont-section .graph-box.grey-bordered {
    height: 85%;
}

.user-data .list-group-item {
    border: none;
    font-size: 16px;
    color: #8E8E93;
    font-family: 'Roboto';
    padding: 14px 10px 14px 20px;
    border-left: 4px solid #E8F0F0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-data .list-group-item.list-group-item-action.active {
    background-color: #E8F0F0;
    color: #1A5C62;
    font-size: 18px;
    font-weight: 600;
    border-left: 4px solid #73BBC3;
}

.user-data .list-group-item.list-group-item-action.active img {
    filter: sepia(1) saturate(470%) hue-rotate(135deg) brightness(0.5) contrast(1.2);
}

.data-box {
    height: 223px;
    background-color: #D9D9D9;
    width: 100%;
    margin-bottom: 14px;
}

.graph-data {
    padding: 10px;
    border-radius: 0px 0px 10px 10px;
    border: 1px solid #D9D9D9;
    text-align: center;
    color: #747474;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-top: 0;
}

.graph-data div {
    flex: 1;
    text-align: center;
}

.graph-data h2 {
    color: #242424;
    font-size: 16px;
    margin-bottom: 0;
}

.graph-data span {
    font-size: 14px;
    color: #747474;
}

.after-line {
    position: relative;
}

.after-line::after {
    position: absolute;
    content: "";
    height: 42px;
    width: 1px;
    background-color: #7C7C7C2E;
    right: 0;
    top: 1px;
}

.graph-data h5 {
    font-size: 10px;
    font-family: 'Roboto';
    color: #242424;
    font-weight: 500;
}

nav.navbar-scrolled {
    position: fixed;
    top: 0;
    left: 0;
}

.home-color.white-header .navbar {
    gap: 40px;
    padding: 0 !important;
    transition: top 0.3s ease-in-out, position 0.3s ease-in-out;
    flex-wrap: nowrap;
}

.home-color.white-header .navbar.navbar-scrolled {
    padding: 0 50px !important;
}

.home-color.white-header .navbar-scrolled #logo {
    content: url(../../components/img/logoLatest.svg);
}

h3.appendix {
    font-size: 14px;
    color: #1D666D;
    font-weight: 600;
    font-family: 'Roboto';
    text-decoration: underline;
    cursor: pointer;
}

.arrow {
    border: solid #1D666D;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-left: 5px;
}

.modal-90w.modal-dialog {
    max-width: 90%;
    /* Adjust this percentage or use pixels for a fixed width */
    min-width: 80%;
}

.w-20 {
    width: 300px;
}

.dupont-section {
    display: inline-flex;
    column-gap: 10px;
    padding-bottom: 20px;
}

@media screen and (min-width: 1200px) {
    .list-group-sidebar {
        width: 280px;
    }
}

.value-box {
    width: 170px;
    background-color: #DCFBFE;
    margin-bottom: 0;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    /* position: absolute; */
    border-right: 4px solid #1D666D;
    min-height: 100px;
}

.value-box .valuation_relative {
    position: relative;
}

.value-box .valuation_relative .i_icon {
    position: absolute;
    right: -5px;
    top: 0;
}

.value-box .valuation_relative .i_icon img {
    height: 18px;
    width: 18px;
}

.value-box span {
    color: #747474;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Roboto';
    width: 75%;
    display: block;
}

.value-box h3 {
    font-size: 18px;
    color: #242424;
    font-family: 'Roboto-bold';
    margin-top: 10px;
    word-wrap: break-word;
}

.er-chart {
    position: relative;
    width: 90%;
    margin-left: auto;
}

.graph-lines img {
    position: absolute;
    right: 32%;
}

.graph-lines {
    width: 100%;
}

.value-box.box1 {
    margin-top: 100px;
}

.value-box.box4 {
    margin-top: 80px;
}

.value-box.box5 {
    position: relative;
    left: 300px;
    height: 140px;
    margin-top: 12px;
}

.g-img1 {
    top: 180px;
}

.g-img2 {
    top: 230px;
}

.g-img3 {
    top: 340px;
}

.g-img4 {
    top: 370px;
}

.curved-lines {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    pointer-events: all;
    right: 50px;
}

.curved-lines.line1 {
    top: 88px;
}

.curved-lines.line2 {
    top: 80px;
}

.curved-lines.line3 {
    top: 120px;
}

.curved-lines.line4 {
    top: 160px;
}

.right-corner-modal {
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    /* Removes any default margin */
    width: auto;
    /* Allows the modal to take its natural width */
    height: auto;
    border-radius: 0;
}

.right-corner-modal .modal-dialog {
    margin: 0;
    /* Removes the default margin applied to the modal */
    border-radius: 0;
}

.right-corner-modal .modal-content {
    border-radius: 0;
}

.back-box {
    width: 100%;
    position: absolute;
    height: 110%;
    width: 90%;
    right: -10px;
    background: #1D666D;
    top: -7px;
    z-index: -1;
    border-radius: 8px;
}

@media screen and (max-width: 768px) {
    div p {
        color: #747474;
        font-size: 14px;
    }

}


.recharts-bar-rectangle:hover {
    fill-opacity: 1 !important;
    /* Ensure no opacity change */
    filter: none !important;
    /* Remove any grey hover effect */
    background-color: transparent !important;
    /* Ensure no background color change */
}

.circle-label text {
    font-size: 14px;
    fill: #fff;
    background-color: #1D666D;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    padding: 5px;
    font-weight: bold;
}

.with-arrow {
    height: 250px !important;
    margin-top: 70px;
}

.with-arrow::after {
    position: absolute;
    content: "";
    right: 0;
    width: 4px;
    height: 200px !important;
    background: #1D666D;
    top: 0;
}

.custom-tooltip {
    background: white;
    /* White background */
    border: 1px solid #ccc;
    /* Optional: Add a border */
    border-radius: 4px;
    /* Optional: Rounded corners */
    padding: 10px;
    /* Optional: Padding */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    /* Optional: Shadow for better visibility */
}

.custom-tooltip p {
    margin: 0;
    /* Remove default margin from paragraphs */
    color: black;
    /* Black text color */
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
    width: 100vw;
    /* Full viewport width */
    position: fixed;
    /* Fix to the entire viewport */
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    /* Optional: Add a subtle background while loading */
    z-index: 9999;
    /* Ensure it stays on top */
}

.spinner {
    width: 4rem;
    height: 4rem;
}

.chart-heading2 {
    text-align: center;
    font-size: 14px;
    font-family: 'Roboto-md';
}

.graph-heading1 {
    font-size: 22px;
    font-weight: 600;
    font-family: "Roboto";
}







/* ========================pie chart css */
.porters-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

/* .donut-chart {
    width: 300px;
    height: 300px;
  }
   */
.segment {
    fill: #73BBC3;
    stroke: #fff;
    stroke-width: 2;
    cursor: pointer;
    transition: fill 0.3s, transform 0.3s;
}

/* .segment.active {
    fill: #1D666D;
    transform: scale(1.08);
  } */

.center-text {
    font-size: 10px;
    fill: #1B373B;
    font-family: 'Roboto';
    font-weight: 600;
}

.segment-label {
    font-size: 12px;
    fill: #fff;
    pointer-events: none;
}

.active-label {
    fill: #fff;
    font-weight: bold;
}

.force-details {
    border: 1.5px solid #73BBC3;
    padding: 24px;
    border-radius: 10px;
}

.force-details h3 {
    margin-bottom: 10px;
    font-size: 20px;
    text-align: left;
}

.segment-label-bg {
    fill: #DBFBFF;
    /* Background color */
    stroke: #BDF8FE;
    /* Border color */
    stroke-width: 3;
    /* opacity: 0.8;     */
}

.active-bg {
    fill: #DBFBFF;
    /* Active background color */
    stroke: #BDF8FE;
}

.segment-label {
    font-size: 10px;
    fill: #1D666D;
    font-weight: bold;
}

.active-label {
    fill: #1D666D;
}



.segment.active {
    stroke-width: 2;
    fill: #1D666D;
}

.segment-label-bg.active-bg {
    stroke: #BDF8FE;
}

.segment-label.active-label {
    fill: #1D666D;
}

.overview_page .table_content .w_md_50 {
    width: 50%;
}

.overview_page .table_content .w_md_50 table {
    width: 100%;
}

.overview_page .table_content {
    display: flex;
    padding: 0 10px;
    gap: 15px;
}

.price_flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.overview_page .price h2 {
    margin: 0;
}

.overview_page .data-heading {
    margin-bottom: 20px;
}

.graph-overflow::-webkit-scrollbar{
    display: block;
}
@media (max-width:767.98px) {

    .overview_page .data-heading {
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }

    .overview_page .price h2 {
        font-size: 18px;
    }

    .overview_page .table_content {
        flex-direction: column;
        padding: 0 20px;
        margin-bottom: 10px;
    }

    .overview_page .table_content .w_md_50 {
        width: 100%;
    }

    .dark-bg .light-bg {
        padding: 12px 10px;
    }
}