
.common-modal .modal-content{
    padding: 32px;
}
.common-modal .modal-content .dilog-img img {
    max-width: 100px;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-bottom: 24px;
}
.common-modal .modal-content .title{
    text-align: center;
}
.common-modal .modal-content .title h3{
    font-size: 28px;
    font-weight: 400;
}
.common-modal .modal-content .title p{
    font-size: 14px;
}
.common-modal .modal-content .actionbtns{
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.common-modal .modal-content .actionbtns button{
    width: 100%;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid;
    transition: .3s;
}
.common-modal .modal-content .actionbtns .theme_btn{
    background: linear-gradient(102.66deg, #1E6E76 49.99%, #83C0C8 120.72%);
    border-color: transparent;
}
.common-modal .modal-content .actionbtns .theme_btn:hover{
    background: transparent;
    color: #1E6E76;
    border-color: #1E6E76;
}
.common-modal .modal-content .actionbtns .theme_bordered{
    background: transparent;
    color: #1E6E76;
    border-color: #1E6E76;
}
.common-modal .modal-content .actionbtns .theme_bordered:hover{
    background: linear-gradient(102.66deg, #1E6E76 49.99%, #83C0C8 120.72%);
    border-color: transparent;
    color: #fff;
}
.common-modal.two-card-modal .modal-dialog {
    max-width: 600px;
}
.common-modal.two-card-modal .modal-content{
    padding: 0;
    background-color: transparent;
}
.common-modal.two-card-modal .modal-dialog .dilog-blue-bg {
    background-color: #1E6E76;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 27px;
    border-radius: 8px 8px 0 0;
    width: 80%;
    margin: auto;
    position: relative;
}
.common-modal.two-card-modal .modal-dialog .roboto-modal .dilog-blue-bg{
    padding: 15%;
    background-color: #DBFBFF;
}
.common-modal.two-card-modal .modal-dialog .roboto-modal .dilog-blue-bg img{
    position: absolute;
}
.common-modal.two-card-modal .modal-dialog .custom-modal-body{
    background-color: #fff;
    padding: 53px;
    box-shadow: -5px -5px 100px 0px #00000040;
    border-radius: 8px;
}
.common-modal.two-card-modal .modal-content .actionbtns button{
    width: 50%;
    margin: auto;
}
.common-modal .modal-content .close{
    position: absolute;
    right: 10px;
    top: 0;
    background-color: transparent;
    z-index: 11;
}
.common-modal.two-card-modal .close{
    right: 50px;
    top: 0;
}