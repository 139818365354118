*{
  box-sizing: border-box;
}

.categories {
    /* background-color: #f8f8f8; */
    background-color: rgba(180,231,237,0.3);
    /* color: #1E6E76; */
    padding: 15px;
    /* border: 1px solid #e1e1e1; */
    border-radius: 13px;
    position: relative;
    /* overflow-y: auto; */
    /* height: 36rem; */
    width: 22rem;
  }
  
  .categories h2 {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    margin-bottom: 10px;
    /* color: #F2DDC2; */
  }

  .categories ul{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    /* row-gap: 1rem !important; */
  }
  
  .categories li {
    list-style: none;
    padding: 0;
  }
  
  .categories li {
    cursor: pointer;
    padding: 10px;
    border-radius: 50px;
    margin: 5px 0;
    transition: background-color 0.1s, color 0.1s;
    font-size: 18px;
    /* outline: 2px solid #F2DDC2; */
    row-gap: 10px;
    height: 50px;
    /* color: #F2DDC2; */
    /* filter: grayscale(100%); */

  }

  .categories li span{
    text-wrap: nowrap;
  }
  
  .categories li:hover {
    /* border: 2px solid #fff; */
    background-color: #1C656D;
    /* color: black; */
    /* opacity: 0.6; */
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    /* padding-block: 15px; */
  }
  
  .categories li:hover span{
    font-size: medium;
    text-transform: uppercase;
  }
  
  .categories li:hover img{
    scale: 1.3;
    
  }
  
  .category_active {
    background-color: #1C656D;
    color: #fff !important;
    /* filter: grayscale(0%) !important; */
  }

  
  .categories li.active:hover {
    /* background-color: #F2DDC2; */
  }
  
  .category_active:hover {
    opacity: 1 !important;
  }
  @media screen and (max-width: 875px) {
    .categories{
      display: none;
    }
  }
  