.stockLine-data {
    /* height: 77px; */
    /* background: #1E6E76; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 30px 0; */
    width: 100%;
}

.stockLine-data p {
    color: #fff;
    margin-bottom: 0;
}

.stock_section .main-heading {
    margin: 40px 0;
}

.stock-line {
    background-image: url(../../components/img/bg-line.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 166px;
    display: flex;
    align-items: center;
}



.main-heading {
    font-size: 36px;
    font-family: "Helvetica sense-serif";
}

.text-theme {
    color: #1C656D !important;
}

table.theme-table th {
    background-color: #DBFBFF;
    padding: 12px 7px;
    font-size: 14px;
    color: #242424;
    font-family: 'Roboto';
    font-weight: 600;
    white-space: nowrap;
    text-align: right;
}
table.theme-table th.underlined{
    text-decoration: underline;
}
table.theme-table th:nth-child(2){
    text-align: left;
}
table.theme-table th:first-child{
    text-align: left;
}

table.theme-table td {
    font-size: 14px;
    padding: 14px 8px;
    color: #666666;
    font-family: 'Roboto';
    font-weight: 400;
    white-space: nowrap;
    text-align: right;
}
table.theme-table td:nth-child(2){
    text-align: left;
}
table.theme-table td:first-child{
    text-align: left;
}
table.theme-table td:first-child {
    text-align: left;
}

table.theme-table td:nth-child(2) {
    text-align: left;
}

tbody tr {
    border-bottom: 1px solid #999999;
}

.color-green {
    color: #08AE05 !important;
}

.color-red {
    color: red !important;
}

.company-read {
    display: flex;
    justify-content: center;
    align-items: center;
}

.company-read img {
    width: 100%;
}

.small-cards {
    padding: 15px;
}

.small-cards h3 {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}

.small-cards p {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 12px;
    color: #747474;
    text-align: center;
    margin-bottom: 0;
}

.small-cards {
    padding: 20px;
    background: #fff;
    box-shadow: 0px 3.84px 95.93px 0px #75757542;
    border-radius: 50%;
    height: 220px;
    width: 220px;
    /* border: 2px solid #1E6E76; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.small-cards img {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 10px;
}

.dotted-circle {
    padding: 40px;
    border: 1px dashed #ccc;
    border-radius: 50%;
    border: dashed;
    border-width: 2px;
    border-color: #ccc;
    background: #fff;
    position: relative;
}

.circle_dot {
    position: absolute;
    height: 18px;
    width: 18px;
    background-color: #216e76;
    right: -14px;
    top: 52%;
    border-radius: 100%;
}

.stock-boxes {
    flex-wrap: nowrap;
}

.dotted-circle.stock-box1::before {
    content: "";
    position: absolute;
    background: #1E6E76;
    top: 80px;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 180px;
    width: 320px;
    border-radius: 300px 300px 20px 20px;
    z-index: -1;
}

.dotted-circle.stock-box2::before {
    content: "";
    position: absolute;
    background: #1E6E76;
    bottom: -95px;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 170px;
    width: 320px;
    border-radius: 20px 20px 300px 300px;
    z-index: -1;
}

.underlined {
    text-decoration: underline;
    /* Underline the text */
    cursor: pointer;
    /* Change cursor to pointer for interactive elements */
}