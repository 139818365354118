@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:opsz,wght@8..144,400;8..144,700;8..144,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.form-group label{
  color: #333333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 100;
}

.signupmain{
  background-color: #fff;
    display: flex;
    /* flex-direction: column; */
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    /* overflow-y: hidden; */
}

.signupleftimg img{
  /* height:100vh; */
}

.signupright{
  width: 488px; 
  position: absolute;
  right: 150px;
  top: 6rem;
}

.signupright h1{
  width:407px;
  color:#1C656D;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 48px;
  font-weight: 100;
  line-height: 135%;
  text-align: left;
  margin-bottom: 48px;
}

.loginData .lbtn{
  align-items: center;
  background: linear-gradient(102.66deg, #1E6E76 49.99%, #83C0C8 120.72%);;
  border-radius: 10px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 13px 60px;
  position: relative;
  border: none;
  color: #fff;
  font-family: "Roboto Serif-Bold", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 22.4px;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.3s;
  height: 60px;
}

.loginData input, select {
  margin-bottom: 15px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  gap: 10px;
  background-color: #F4F4F4;
  padding: 15px 10px;
  width: 100%;
  position: relative;
}
.eye-icon {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
}
.loginData input::placeholder {
  color: #999999;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

#remember{
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  margin-top:0;
  text-decoration:none;
  color:#666666;
  white-space: nowrap;
}

#remember-me:focus {
  outline: none;
  border: none;
  /* outline: 2px solid #1E6E76; */
}

.loginData input:focus,select:focus {
  /* outline: none; */
  outline: 2px solid #1E6E76;
}

#remember-me{
  width: 18px;
  aspect-ratio: 1;
  border: 1px solid #666666;
  border-radius: 0px;
  /* background-color: #fff; */
}

hr{
  width: 100%;
  color: #999999;
}
#forgot {
  font-size: 14px;
   font-weight: 400;
}
.login-side {
  height: 100%;
  width: 50%;
  object-fit: cover;
}
.or{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-block: 20px;
  color: #999999;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 100;
}

.loginData button {
  color: #fff;
  border: none;
  outline: 2px solid transparent;
  cursor: pointer;
  background:#1C656D;
  width: 100%;
  height: 40px;
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  gap: 10px;
  font-family: Roboto Serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  transition: all 0.3s ease;
}

[aria-labelledby="button-label"] {
 background-color: #ffffff !important;
  color: #666666 !important;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  height: 48px !important;
}


.loginData button:hover {
  outline: 2px solid #1C656D;
  color: #1C656D;
  background-color: #fff;
  background: #fff;
}
.loginData .form_fields{
  margin-bottom: 40px;
}
  /* .signin_page .loginData .lbtn{
    margin-bottom: 40px;
  } */
  .error {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    top: -5rem;
    color: #a94442;
    background-color: #f2dede;
    border: 1px solid #ebccd1;
    padding: 15px;
    margin-bottom: 15px;
  }




  /* ////////////////new css */
  .loginData h1 {
    font-size: 36px;
    font-family: Arial, Helvetica, sans-serif;
    color: #1C656D;
  
}
.google-btn iframe{
  width: 100%!important;
}


.input-container {
  position: relative;
}

  /* .haAclf{
    width: 80%!important;
  } */