.flex_box {
    display: flex;
    gap: 46px;
}

.flex_box .col_md_3 {
    width: 25%;
}

.flex_box .col_md_9 {
    width: 75%;
}

.flex_box .list-group {
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .flex_box {
        flex-direction: column;
    }
    .flex_box .col_md_3 {
        width: 100%;
    }
    
    .flex_box .col_md_9 {
        width: 100%;
    }
}