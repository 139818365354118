@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.bcontainer {
    display: flex;
    /* justify-content: center; */
    align-items: start;
    height: 100vh;
}

.bcontainer main {
    width: 100%;
    height: 350px;
    background-color: #B4E7ED;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bcontainer main>div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.exp {
    font-family: "Noto Sans Thai", sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #292929;
}

.pricerelated {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pricerelated span {
    font-family: "Noto Sans Thai", sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: #292929;
}

.pricerelated p {
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
}

.pricerelated p span:nth-child(1) {
    font-family: "Noto Sans Thai", sans-serif;
    font-weight: bold;
    font-size: 48px;
    color: #292929;
}

.pricerelated p span:nth-child(2) {
    font-family: "Noto Sans Thai", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #292929;
}

.bcontainer main h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 100;
}

.bcontainer main p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
}

.subfeature{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.ulfeature{
    text-decoration: none;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 30px;

}

.ulfeature li{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.plans__header {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    justify-content: center;
}

.plans {
    position: relative;
    width: 357px;
    height: 543px;
    background-color: #F9F9F9;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.lbtn {
    align-items: center;
    background-color: #393939;
    border-radius: 10px;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    padding: 13px 60px;
    position: relative;
    border: none;
    color: #fff;
    font-family: "Noto Sans Thai", sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 22.4px;
    position: relative;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
}

#plans2 {
    position: relative;
    top: 50px;
    background-color: #DCFBFE !important;
}
.plans::after {
    /* position: absolute;
    top: 0;
    left: 0;
    content: 'BASIC';
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    /* width: 100%; */
    /* height: 100%; */
    /* color: #000; */
    /* font-size: 2.5em; */
    /* font-weight: 900; */
    /* background-color: #318c96; */
    /* font-size: 1.2em;
    width: 45%;
    height: 14%;
    aspect-ratio: 1; */
    /* margin-left: 20px; */
    /* margin-top: 20px; */
    /* animation: adjust 0.5s forwards; */
}

/* #plan2::after {
    content: 'PRO';
}

#plan3::after {
    content: 'PREMIUM';
    padding-inline: 10px;
} */

.plans:hover {
    /* cursor: pointer; */
}

.plans:hover::after {
    cursor: pointer;
}

.plans__content {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 30%;
}

.plans__content span {
    margin-bottom: 20px;
}

footer{
    position: relative;
    /* bottom: -10rem; */
}
@keyframes expand {
    from {
        height: 200px;
    }

    to {
        height: 300px;
    }
}

@keyframes adjust {
    from {
        font-size: 2.5em;
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        margin-left: 0;
        margin-top: 0;
    }

    to {
        font-size: 1.2em;
        width: 45%;
        height: 14%;
        aspect-ratio: 1;
        margin-left: 20px;
        margin-top: 20px;
    }
}